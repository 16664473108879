.wrapperEditor {
    display: flex;
    justify-content: space-between;
}
.wrapperAllButtons {
    display: flex;
    flex-wrap: wrap;
    width: 50%;
}

.wrapperEditorInner {
    border: 1px solid var(--color-dark-border);
    display: flex;
    width: 50vw;
    height: auto;
    min-height: 20vh;
    flex-direction: column;
}
.wrapperRightWrapper {
    display: flex;
    flex-direction: column;
}
.wrapperRightWrapperIsClose {
    display: none;
}
.btnsEditor {
    display: flex;
    align-items: center;
    justify-content: center;
}
