.wrapperComponents {
    display: flex;
    flex-direction: row;
    justify-content: center;
    .btnAddComponent {
        height: 50px;
        margin: 0px 10px;
        cursor: pointer;
        background-color: var(--color-green);
        border-style: none;
        border-radius: 7px;
        color: var(--color-white-one);
        font-size: 14px;
        font-weight: bold;
        &:active {
            background-color: var(--color-light-green);
        }
    }
}
.innerComponentAndPreview {
    display: flex;
    //justify-content: flex-start;
    width: 50%;
    flex-direction: column;
}
.wrapperNameGroup {
    margin-left: 12px;
    font-size: 20px;
}
