:root {
    --color-dark: #c9c9c9;
    --color-dark-border: #404040;
    --color-white-one: #fff;
    --color-blue: #3434af;
    --color-blue-background: #8989c9a8;
    --color-light-gray: #f2f2f2;
    --color-light-green: #72cf7c;
    --color-light-blue: #dff0fe;
    --color-blue-two: #4788c7;
    --color-red: #ff8181;
    --color-green: #90e790;
    --color-cadetblue: #5f9ea0;

    --md-radius: 0.3125rem;
}
:root {
    --color-back-component: #3434af;
}
