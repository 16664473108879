.wrapperCartContent {
    display: flex;
    justify-content: space-between;
    .btnClose {
        width: 30px;
        height: 30px;
        border-radius: 7px;
        border: none;
        cursor: pointer;
        background-image: url('../../../shared/assets/xmark.svg');
        &:active {
            background-color: var(--color-red);
        }
    }
}
