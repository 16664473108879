.wrapperPopup {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	//width: 40%;
	height: 480px;
	background-color: rgb(95, 107, 160);
	.closeBtn {
		// position: absolute;
		// left: 103%;
		width: 30px;
		height: 30px;
		border-radius: 7px;
		border: none;
		cursor: pointer;
		background-image: url('../../shared/assets/xmark.svg');
		&:active {
			background-color: var(--color-red);
		}
	}
	.updateBtn {
		top: 10%;
		width: 30px;
		height: 30px;
		border-radius: 7px;
		border: none;
		cursor: pointer;
		background-position: center;
		background-image: url('../../shared/assets/update.svg');
		background-repeat: no-repeat;
		svg {
			width: 5px;
			height: 5px;
		}
		&:active {
			background-color: var(--color-green);
		}
	}
	.createBtn {
		top: 10%;
		width: 30px;
		height: 30px;
		border-radius: 7px;
		border: none;
		cursor: pointer;
		background-position: center;
		background-image: url('../../shared/assets/create.svg');
		background-repeat: no-repeat;
		svg {
			width: 5px;
			height: 5px;
		}
		&:active {
			background-color: var(--color-green);
		}
	}
	.wrapperName {
		font-size: 18px;
		margin: 5px;
	}
	.inputContent {
		height: 380px;
		resize: none;
		// overflow: hidden;
		line-height: 1.5;
	}
}
.btnsWrapper {
	margin-top: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
